export enum ContactType {
  email = 'email',
  facebook = 'facebook',
  twitter = 'twitter',
  github = 'github',
  instagram = 'instagram',
}

export enum LinkType {
  email = 'email',
  url = 'url'
}

export type Contact = { [key in ContactType]: string; };

const contact = {
  email: 'malyawka@gmail.com',
  facebook: 'www.facebook.com/pavel.pustovalov.96',
  twitter: 'twitter.com/thegoodmanzzz',
  github: 'github.com/malyawka',
  instagram: 'www.instagram.com/puspavel',
};

export default contact;

