import projects, { IBody, IProject } from './projects';
import contact, { Contact, ContactType, LinkType } from './contact';

interface IConfig {
  name: string;
  fullName: string;
  intro: string;
  about: string;
  resume: string;
  contact: Contact;
  availableForHire: boolean;
  renderArticles: boolean;
  devToUsername: string;
  projects: IProject[];
  privacy: IBody[];
  terms: IBody[];
}

const Config: IConfig = {
  name: 'MalyaWka',
  fullName: 'Pavel MalyaWka Pustovalov',
  intro: 'A indie developer who loves make games and values learning and growing with people, teams, and technologies.',
  about: 'Since 2017, I am a software engineer and 2D / 3D artist who loves to collaborate and create interesting, beautiful games and applications. I write code in a dynamic and complex environment, with a focus on first-hand experience in developing high-quality software that meets the latest requirements, budget and schedule. In addition, I am an artist and illustrator who values learning and development together with people, participates in collaborations and constantly shares my experience.',
  resume: 'https://drive.google.com/',
  renderArticles: false,
  availableForHire: false,
  devToUsername: 'malyawka',
  contact,
  projects,
  privacy: [
    {
      title: 'Privacy Policy',
      description: 
      'MalyaWka (Alfeshka, PALS Dev Studio) built the all apps as a free apps. This SERVICE is provided by MalyaWka (Alfeshka, PALS Dev Studio) at no cost and is intended for use as is.\n' +
      'This page is used to inform visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone decided to use my Service.\n' +
      'If you choose to use my Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that I collect is used for providing and improving the Service. I will not use or share your information with anyone except as described in this Privacy Policy.\n' +
      'The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at apps unless otherwise defined in this Privacy Policy.',
    },
    {
      title: 'Information Collection and Use',
      description:
      'For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information, including but not limited to E-mail, Device ID, Ads ID. The information that I request will be retained on your device and is not collected by me in any way.\n' +
      'The app does use third party services that may collect information used to identify you.\n' +
      'Link to privacy policy of third party service providers used by the apps:',
      link: [
        {
          type: LinkType.url,
          title: 'Google Play Services',
          url: 'www.google.com/policies/privacy/'
        },
        {
          type: LinkType.url,
          title: 'AdMob',
          url: 'support.google.com/admob/answer/6128543?hl=en'
        },
        {
          type: LinkType.url,
          title: 'Google Analytics for Firebase',
          url: 'firebase.google.com/policies/analytics'
        },
        {
          type: LinkType.url,
          title: 'Firebase Crashlytics',
          url: 'firebase.google.com/support/privacy/'
        },
        {
          type: LinkType.url,
          title: 'Facebook',
          url: 'www.facebook.com/about/privacy/update/printable'
        },
        {
          type: LinkType.url,
          title: 'Unity',
          url: 'unity3d.com/legal/privacy-policy'
        },
      ]
    },
    {
      title: 'Log Data',
      description:
      'I want to inform you that whenever you use my Service, in a case of an error in the app I collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics.'
    },
    {
      title: 'Cookies',
      description:
      'Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device\'s internal memory.\n' +
      'This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.'
    },
    {
      title: 'Service Providers',
      description:
      'I may employ third-party companies and individuals due to the following reasons:\n' +
      ' • To facilitate our Service;\n' +
      ' • To provide the Service on our behalf;\n' +
      ' • To perform Service-related services;\n' +
      ' • To assist us in analyzing how our Service is used.\n' +
      'I want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.'
    },
    {
      title: 'Security',
      description:
      'I value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security.'
    },
    {
      title: 'Links to Other Sites',
      description:
      'This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites. I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.'
    },
    {
      title: 'Children’s Privacy',
      description:
      'These Services do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that I will be able to do necessary actions.'
    },
    {
      title: 'Changes to This Privacy Policy',
      description:
      'I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page.\n' +
      'This policy is effective as of 2020-12-20.'
    },
    {
      title: 'Contact Us',
      description:
      'If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact me at:',
      link:[
        {
          type: LinkType.email,
          title: 'malyawka@gmail.com',
          url: 'malyawka@gmail.com'
        },
        {
          type: LinkType.email,
          title: 'alfeshka@gmail.com',
          url: 'alfeshka@gmail.com'
        },
        {
          type: LinkType.email,
          title: 'devstudio.pals@gmail.com',
          url: 'devstudio.pals@gmail.com'
        }
      ]
    }
  ],
  terms: [
    {
      title: 'Terms & Conditions',
      description: 
      'By downloading or using all apps, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the apps. You’re not allowed to copy, or modify the apps, any part of the apps, or our trademarks in any way. You’re not allowed to attempt to extract the source code of the apps, and you also shouldn’t try to translate the app into other languages, or make derivative versions. The apps itself, and all the trade marks, copyright, database rights and other intellectual property rights related to it, still belong to MalyaWka (Alfeshka, PALS Dev Studio).\n' +
      'MalyaWka (Alfeshka, PALS Dev Studio) is committed to ensuring that the apps is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the apps or its services without making it very clear to you exactly what you’re paying for.\n' +      
      'All apps stores and processes personal data that you have provided to us, in order to provide my Service. It’s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that apps won’t work properly or at all.\n' +
      'All apps does use third party services that declare their own Terms and Conditions.\n' +
      'Link to Terms and Conditions of third party service providers used by all apps:',
      link: [
        {
          type: LinkType.url,
          title: 'Google Play Services',
          url: 'policies.google.com/terms',
        },
        {
          type: LinkType.url,
          title: 'AdMob',
          url: 'developers.google.com/admob/terms',
        },
        {
          type: LinkType.url,
          title: 'Google Analytics for Firebase',
          url: 'firebase.google.com/terms/analytics',
        },
        {
          type: LinkType.url,
          title: 'Firebase Crashlytics',
          url: 'firebase.google.com/terms/crashlytics',
        },
        {
          type: LinkType.url,
          title: 'Facebook',
          url: 'www.facebook.com/legal/terms/plain_text_terms',
        },
        {
          type: LinkType.url,
          title: 'Unity',
          url: 'unity3d.com/legal/terms-of-service',
        },
      ]
    },
    {
      title: 'Changes to This Terms and Conditions',
      description: 'I may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Terms and Conditions on this page.\n' +
      'These terms and conditions are effective as of 2020-12-20.',
    },
    {
      title: 'Contact Us',
      description: 'If you have any questions or suggestions about my Terms and Conditions, do not hesitate to contact me at:',
      link: [
        {
          type: LinkType.email,
          title: 'malyawka@gmail.com',
          url: 'malyawka@gmail.com',
        },
        {
          type: LinkType.email,
          title: 'alfeshka@gmail.com',
          url: 'alfeshka@gmail.com',
        },
        {
          type: LinkType.email,
          title: 'devstudio.pals@gmail.com',
          url: 'devstudio.pals@gmail.com',
        },
      ]
    }
  ]
};

export * from './projects';
export * from './contact';
export default Config;